import React, { useContext, useState } from "react";
import { PradoVx, apartmentImages, Avensis } from "../../images/index";
import { ContentContext } from "../layout";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, CardActions, Skeleton } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link } from "gatsby";
import NavbarBack from "../navbarBack";
import "./style.css";
import Lazy from "../lazyAnimation";

const ChoicePage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { translate } = useContext(ContentContext);

  return (
    <>
      {loading ? (
        <div className="services">
          <div className="frst">
            <div className="snd">
              <div className="skeleton-services">
                <Skeleton
                  animation="wave"
                  sx={{
                    bgcolor: "grey.500",
                    width: "50%",
                    height: "10px",
                    borderRadius: "10px",
                    marginBottom: "20px",
                  }}
                  variant="rectangular"
                />
                <Skeleton
                  animation="wave"
                  sx={{
                    bgcolor: "grey.500",
                    width: "70%",
                    height: "10px",
                    borderRadius: "10px",
                    marginBottom: "10px",
                  }}
                  variant="rectangular"
                />
                <Skeleton
                  animation="wave"
                  sx={{
                    bgcolor: "grey.500",
                    width: "50%",
                    height: "10px",
                    borderRadius: "10px",
                    marginBottom: "10px",
                  }}
                  variant="rectangular"
                />
                <Skeleton
                  animation="wave"
                  sx={{
                    bgcolor: "grey.500",
                    width: "100%",
                    height: "150px",
                    borderRadius: "10px",
                    marginBottom: "10px",
                  }}
                  variant="rectangular"
                />
                <Skeleton
                  animation="wave"
                  sx={{
                    bgcolor: "grey.500",
                    width: "50%",
                    height: "10px",
                    borderRadius: "10px",
                    marginBottom: "10px",
                  }}
                  variant="rectangular"
                />
                <Skeleton
                  animation="wave"
                  sx={{
                    bgcolor: "grey.500",
                    width: "100%",
                    height: "150px",
                    borderRadius: "10px",
                    marginBottom: "10px",
                  }}
                  variant="rectangular"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Box className="services">
          <Lazy
            type="slide"
            delay={150}
            timeout={1000}
            direction="left"
            className="slide-container"
          >
            <Box className="frst">
              <Box className="snd">
                <Box component="div" className="mobile">
                  <Link to="/">
                    <NavbarBack title={translate("home_page")} />
                  </Link>
                  <Typography
                    variant="subtitle1"
                    id="header-text"
                    component="div"
                    sx={{
                      fontWeight: "bold",
                      marginBottom: 3,
                      color: "#302f2f",
                    }}
                  >
                    {translate("choose_head_text")}
                  </Typography>
                  <Typography
                    id="card1-text"
                    variant="subtitle2"
                    component="div"
                    sx={{
                      fontWeight: "regular",
                      marginBottom: 1,
                      fontSize: "15px",
                      color: "#302f2f",
                    }}
                  >
                    {translate("get_a_car_anytime")}
                  </Typography>
                  <Card
                    className="card1"
                    sx={{
                      boxShadow: "none",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 4,
                      marginBottom: 3,
                      border: " 1px solid #9a9797",
                      transition: "all .2s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                  >
                    <CardActionArea>
                      <Link to="/book-a-car" className="fade-in-right">
                        <CardMedia
                          component="img"
                          alt="apartment images"
                          height="130"
                          image={PradoVx}
                          sx={{
                            objectFit: "contain",
                            backgroundColor: "white",
                            padding: "16px",
                          }}
                          loading="lazy"
                        />
                        <CardContent>
                          <Typography
                            id="car-text"
                            sx={{
                              textAlign: "center",
                              fontWeight: "regular",
                              fontSize: "15px",
                              color: "#302f2f",
                            }}
                            gutterBottom
                            variant="body2"
                            component="div"
                            color="text.secondary"
                          >
                            {translate("vehicle")}
                          </Typography>
                        </CardContent>
                      </Link>
                    </CardActionArea>
                  </Card>
                  <Typography
                    id="card2-text"
                    variant="subtitle2"
                    component="div"
                    sx={{
                      fontWeight: "regular",
                      marginBottom: 1,
                      fontSize: "15px",
                      color: "#302f2f",
                    }}
                  >
                    {translate("get_an_apartment_anytime")}
                  </Typography>
                  <Card
                    className="card2"
                    sx={{
                      boxShadow: "none",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 4,
                      border: " 1px solid #9a9797",
                      transition: "all .2s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                  >
                    <CardActionArea>
                      <Link to="/book-an-apartment">
                        <CardMedia
                          component="img"
                          alt="apartment images"
                          height="130"
                          image={apartmentImages[2]}
                          loading="lazy"
                        />
                        <CardContent>
                          <Typography
                            id="apart-text"
                            sx={{
                              textAlign: "center",
                              fontWeight: "regular",
                              fontSize: "15px",
                              color: "#302f2f",
                            }}
                            gutterBottom
                            variant="body2"
                            component="div"
                            color="text.secondary"
                          >
                            {translate("apartment")}
                          </Typography>
                        </CardContent>
                      </Link>
                    </CardActionArea>
                  </Card>
                </Box>
              </Box>
            </Box>
          </Lazy>
        </Box>
      )}
    </>
  );
};

export default ChoicePage;
