import React from 'react'
import ChoicePage from '../../components/choicePage'
import Layout from '../../components/layout';

const Service = () => {
  return (
    <Layout>
      <ChoicePage />
    </Layout>
  )
}

export default Service;