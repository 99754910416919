import React, { createContext, useEffect, useState } from "react";
import { User, UserContext } from "../../context/AuthContext";
import { auth } from "../../config/firebase";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import Loader from "../Loader";
import { graphql, useStaticQuery, Script } from "gatsby";

interface Props {
  children?: React.ReactNode;
}

type LocalAttrs = {
  attributes: {
    key: string;
    value: string;
    locale: string;
  };
};

type Asset = {
  key: string;
  value: {
    data: {
      value: string;
    };
  };
  locale: string;
  localizations: {
    data: LocalAttrs[];
  };
};

export type PageInfo = {
  name: string;
  title: string;
  uri: string;
  bw_assets: Asset[];
};

type ContentData = {
  allStrapiBwPage: {
    nodes: PageInfo[];
  };
};

type ContentContext = {
  translate: (key: string) => string;
};

const TAG_ID = 'G-5RWC7G019X'

export const ContentContext = createContext<ContentContext>({
  translate: (key: string) => key,
});

const Layout = ({ children }: Props) => {
  const getLang = (): string | null => {
    const ln_local =
      typeof window !== "undefined"
        ? localStorage.getItem("defaultLanguage")
        : null;

    const SYSTEM_LANGUAGE = ln_local || setDefaultLanguage();

    return SYSTEM_LANGUAGE;
  };

  const setDefaultLanguage = (lang?: string | null): string | null => {
    if (typeof window !== "undefined") {
      const systemLanguage = localStorage.getItem("defaultLanguage")
        ? localStorage.getItem("defaultLanguage")
        : navigator.language.split("-")[0];
      localStorage.getItem("defaultLanguage")
        ? "null"
        : localStorage.setItem(
          "defaultLanguage",
          lang?.toLocaleUpperCase() || systemLanguage.toLocaleUpperCase()
        );
      return systemLanguage.toLocaleUpperCase();
    } else return null;
  };

  const [lang, setLang] = useState(setDefaultLanguage());

  useEffect(() => {
    const lang = getLang();
    if (lang) setLang(lang);
  }, []);

  const data: ContentData = useStaticQuery(graphql`
    query Content {
      allStrapiBwPage {
        nodes {
          bw_assets {
            locale
            key
            value {
              data {
                value
              }
            }
            localizations {
              data {
                attributes {
                  key
                  locale
                  value
                }
              }
            }
          }
          uri
          title
          name
        }
      }
    }
  `);
  

  const pageInfo: PageInfo | null | undefined =
    typeof window !== "undefined"
      ? data.allStrapiBwPage.nodes.find(
        (page) => page.uri === location.pathname.replace(/\/+$/, '')
      )
      : null;

  const translate = (key: string): string => {
    if (!pageInfo) return key;
  
    const asset = pageInfo.bw_assets.find((a) => a.key === key);
    if (!asset) return key;
    let value = asset?.value?.data?.value;
    if (lang != "EN") {
      const localAttr = asset.localizations.data.find(
        (l) => l.attributes.locale == lang?.toLocaleLowerCase()
      );
      if (localAttr) return (value = localAttr.attributes.value);
    }
    return value;
  };

  const [isAuth, setAuth] = useState<boolean>(false);
  const [user, setUser] = useState<User>({
    displayName: "",
    email: "",
    phoneNumber: "",
    emailVerified: null,
    uid: null,
  });
  const [loading, setLoading] = useState<boolean>(true);

  const signInUser = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logOut = async () => {
    setLoading(true);
    new Promise((resolve) => setTimeout(resolve, 500)).then((r) => {
      auth.signOut();
    });
    setLoading(false);
  };


  useEffect(() => {
    function checkAuthUser() {
      setLoading(true);
      onAuthStateChanged(auth, (user) => {
        if (user) {
          const { displayName, email, emailVerified, phoneNumber, uid } = user;
          setAuth(false);
          setUser({ displayName, email, emailVerified, phoneNumber, uid });
        } else {
          setAuth(false);
        }
        setLoading(false);
      });
    }
    checkAuthUser();
    return () => { };
  }, []);

  return (

    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${TAG_ID}`}
        strategy="off-main-thread"
      />
      <Script id="gtag-config" strategy="off-main-thread" forward={[`gtag`]}>
        {`
    window.dataLayer = window.dataLayer || []
    window.gtag = function gtag() { window.dataLayer.push(arguments) }
    gtag('js', new Date())
    gtag('config', '${TAG_ID}')
  `}
      </Script>
      <UserContext.Provider
        value={{ signInUser, logOut, isAuth, setAuth, user, setUser }}
      >

        <ContentContext.Provider value={{ translate }}>
          {!loading ? <>{children}</> : <Loader />}
        </ContentContext.Provider>
      </UserContext.Provider></>
  );
};

export default Layout;
