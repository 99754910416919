import { UserCredential } from "firebase/auth";
import React, { createContext } from "react";

export type User = {
  displayName: string | null;
  email: string | null;
  phoneNumber: string | null;
  emailVerified: boolean | null;
  uid: string | null
};

type UserContextType = {
  signInUser: (email: string, password: string) => Promise<UserCredential>;
  logOut: () => void;
  isAuth: boolean;
  setAuth: React.Dispatch<React.SetStateAction<boolean>>;
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
};

export const UserContext = createContext<UserContextType>({
  signInUser: (email: string, password: string) =>
    new Promise<UserCredential>((resolve, reject) => null),
  logOut: () => null,
  isAuth: false,
  setAuth: () => null,
  user: { displayName: "", email: "", phoneNumber: "", emailVerified: null, uid: null },
  setUser: () => null,
});
